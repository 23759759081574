import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

export const getWesReports = createAsyncThunk('getWesReports', async (data) => {
  const searchParams = data.searchParams
  let searchString = `?page=${data.page}`
  
  for(let key of Object.keys(searchParams)){
    if(searchParams[key].length !== 0){
      searchString = searchString + `&q[${key}]=${searchParams[key]}`
    }
  }

  const reports = await fetch(`${process.env.REACT_APP_API_URL}/wes_reports${searchString}`, {
    method: 'GET',
    headers: {"Content-Type": "application/json", token: data.token}
  })

  return(reports.json())
})

export const wesReportsSlice = createSlice({
  name: 'wesReports',
  initialState: {
    loading: false,
    data: [],
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWesReports.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default wesReportsSlice.reducer;