import React, {useState, useEffect} from 'react'
import { Container, Modal, Button, Form, Alert} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

import { getCustomers } from '../../state/CustomerSlice'

import { translator } from '../../scripts/translator'

import QspecModal from '../widgets/modals/QspecModal'

function PurchaseOrderLinesModal({showModal, hideModal, savePol, currentPol, purchaseOrderId, currentUser}) {
  const dispatch = useDispatch();
  const [showQspec, setShowQspec] = useState(false)
  const [qspecFocus, setQspecFocus] = useState(false)
  const [params, setParams] = useState({
    company_id: null, 
    product_number: '', 
    amount: 1, 
    aqua_enabled: true, 
    wes_enabled: false, 
    amount_completed: 0, 
    expected_production_done_date: '', 
    actual_production_done_date: '', 
    purchase_order_id: purchaseOrderId, 
    note: '', 
    serialNumberBegin: 0, 
    serialNumberEnd: '',
    po_line_id: '',
    internal_order_for_pack: false, 
    all_received_date: '', 
    total_shipped_amount: 0, 
    cell_quality_report: null, 
    technical_documents: [], 
    qspec: '', 
    wes_spec: ''
  })
  const [showQspecs, setShowQspecs] = useState(false)
  const [showWes, setShowWes] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const [warnings, setWarnings] = useState([])
  const customers = useSelector(state => state.customers.data)

  useEffect(() => {
    getCompanies();
    handleErrors(params);
    handleWarnings(params);
    calculateSerialNumberEnd(params);
  }, [])
  
  useEffect(() => {
    if(showModal === false){ resetParams() }
    if(Object.keys(currentPol).length !== 0){ setPolParams() }
  }, [showModal, currentPol]) // eslint-disable-line react-hooks/exhaustive-deps

  const getCompanies = () => dispatch(getCustomers({token: currentUser.token}))

  const setPolParams = () => {
    const data = {...params}
    for(let key of Object.keys(params)){   
      if (key !== 'serialNumberEnd') {  // Don't copy serialNumberEnd, it will be calculated
        data[key] = currentPol[key]
      }
    }
    
    // Ensure po_line_id is correctly set from the current POL
    if (currentPol.po_line_id) {
      data.po_line_id = currentPol.po_line_id;
    }
    
    // Ensure amount and amount_completed have valid values
    data.amount = currentPol.amount || 1;
    data.amount_completed = currentPol.amount_completed || 0;
    
    data['qspec'] = !!currentPol.qspecs && currentPol.qspecs.length !== 0 ? stringifyQspec(currentPol.qspecs[currentPol.qspecs.length -1]['data']) : ''
    data['wes_spec'] = !!currentPol.wes_specs && currentPol.wes_specs.length !== 0 ? stringifyWesSpec(currentPol.wes_specs[currentPol.wes_specs.length -1]['data']) : ''
    
    // Calculate serial number end
    calculateSerialNumberEnd(data);
    
    setParams(data);
    handleErrors(data);
    handleWarnings(data);
  } 

  // Calculate serial number end based on begin and amount
  const calculateSerialNumberEnd = (data) => {
    const begin = parseInt(data.serialNumberBegin);
    const amount = parseInt(data.amount);
    
    if (!isNaN(begin) && !isNaN(amount) && begin > 0 && amount > 0) {
      data.serialNumberEnd = begin + amount - 1;
    } else {
      data.serialNumberEnd = '';
    }
    
    return data;
  }

  const stringifyQspec = (qspec) => JSON.stringify(qspec).replaceAll(',', ', \n')
  const stringifyWesSpec = (wes_spec) => JSON.stringify(wes_spec).replaceAll(',', ', \n')

  const updateParams = (key, value) => {
    const data = {...params};
    
    if(key === 'technical_documents'){
      data[key] = [...params[key], value];
    } else if(key === 'testing_framework' && value === 'none') {
      // If selecting none, disable both
      data['aqua_enabled'] = false;
      data['wes_enabled'] = false;
    } else if(key === 'aqua_enabled' && value === true) {
      // If enabling aqua, disable wes
      data['aqua_enabled'] = true;
      data['wes_enabled'] = false;
    } else if(key === 'wes_enabled' && value === true) {
      // If enabling wes, disable aqua
      data['wes_enabled'] = true;
      data['aqua_enabled'] = false;
    } else {
      data[key] = value;
    }
    
    // If serialNumberBegin or amount changed, recalculate the end
    if (key === 'serialNumberBegin' || key === 'amount') {
      calculateSerialNumberEnd(data);
    }
    
    // Ensure amount has a minimum value of 1
    if (key === 'amount' && (value === '' || parseInt(value) < 1)) {
      data.amount = 1;
    }
    
    setParams(data);
    handleErrors(data);
    handleWarnings(data);
  }

  const deleteDocument = async (file) => {
    const data = {...params};
    data['technical_documents'] = data['technical_documents'].filter(document => file !== document);
    setParams(data);

    if(typeof file.id != 'undefined'){
      const data = await fetch(`${process.env.REACT_APP_API_URL}/purchase_order_lines/delete_file?id=${file.id}`, {
        method: 'GET',
        headers: {token: currentUser.token}
      });
      const response = await data.json();
    }
  }

  const resetParams = () => {
    const initialParams = {
      company_id: null, 
      product_number: '', 
      amount: 1, 
      aqua_enabled: true, 
      wes_enabled: false, 
      amount_completed: 0, 
      expected_production_done_date: '', 
      actual_production_done_date: '', 
      purchase_order_id: purchaseOrderId, 
      note: '', 
      serialNumberBegin: 0, 
      serialNumberEnd: '',
      po_line_id: '',
      internal_order_for_pack: false, 
      all_received_date: '', 
      total_shipped_amount: 0, 
      cell_quality_report: null, 
      technical_documents: [], 
      qspec: '', 
      wes_spec: ''
    };
    
    // Calculate the initial serial number end
    calculateSerialNumberEnd(initialParams);
    
    setParams(initialParams);
  }
  
  const handlePol = () => {
    const data = {...params};
    data['qspec'] = params['qspec'].replace('""', 0);
    data['wes_spec'] = params['wes_spec'].replace('""', 0);
    
    if(errorMessages.length === 0){
      savePol(data);
      hideModal();
    } else {
      setErrorMessages(errorMessages);
      setTimeout(() => {
        setErrorMessages([]);
        setWarnings([]);
      }, 5000);
    }
  }

  const handleWarnings = (data) => {
    let messages = [];
    try {
      if(!JSON.parse(data.qspec)['internalResistanceTest'] || JSON.parse(data.qspec)['internalResistanceTest'] === false){
        messages.push('Be sure this qspec is for a prototype before you save');
      }
    } catch {
      console.log('something went wrong');
    }
    setWarnings(messages);
  }

  const handleErrors = (data) => {
    let errors = [];
    if(data.product_number.length < 4){
      errors.push(translator('product number too short'));
      errors.push("product number is too short (minimum is 4 characters) and equal an existing product");
    }
    
    if(data['qspec'].length !== 0) { 
      try {
        JSON.parse(data['qspec']);
      } catch (error) {
        errors.push('Qspec is not JSON');
      }
    }
    
    if(data['wes_spec'].length !== 0) { 
      try {
        JSON.parse(data['wes_spec']);
      } catch (error) {
        errors.push('Wes spec is not JSON');
      }
    }
    
    setErrorMessages(errors);
  }

  const isValidJson = (data) => {
    try {
      JSON.parse(data);
      return true;
    } catch (error) {
      return false;
    }
  }

  const openModal = () => setShowQspec(true);

  const closeModal = () => setShowQspec(false);
  
  return (
    <Modal size='xl' show={showModal} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{Object.keys(currentPol).length !== 0 ? 'Update Purchase Order Line' : 'New Purchase Order Line'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className='w-100 d-flex flex-wrap justify-content-between'>
          <div style={{minWidth: '450px'}}>
            <div className='mb-4'>
              <Form.Label>{translator('cusotmer')}</Form.Label>
              <Form.Select id='customer' value={params.company_id || ''} onChange={e => updateParams('company_id', parseInt(e.target.value))}>
                <option value=''>{translator('select customer')}</option>
                {Array.isArray(customers) && customers.map((customer, index) => (
                  <option key={customer.name + index} value={customer.id}>{customer.name}</option>
                ))}
              </Form.Select>
            </div>
            
            {/* Display po_line_id read-only for existing records */}
            {Object.keys(currentPol).length !== 0 ? (
              <div className='mb-4'>
                <Form.Label>{translator('po line id')}</Form.Label>
                <Form.Control 
                  id='pol-id' 
                  value={params.po_line_id} 
                  readOnly 
                  className="bg-light"
                />
              </div>
            ) : (
              <div className='mb-4'>
                <Form.Label>{translator('po line id')}</Form.Label>
                <p className="form-control-plaintext border bg-light p-2">
                  {translator('ID will be assigned automatically')}
                </p>
              </div>
            )}
            
            <div className='mb-4'>
              <Form.Label>{translator('product number')}</Form.Label>
              <Form.Control 
                id='product-number' 
                value={params.product_number || ''} 
                onChange={e => updateParams('product_number', e.target.value)}
              />
            </div>
            
            {/* Mutually exclusive controls for Aqua and WES - displayed as a group with better styling */}
            <div className='mb-4'>
              <label className="form-label mb-2">Testing Framework</label>
              <div className="d-flex gap-4 p-3 border rounded">
                <div>
                  <Form.Check 
                    id='none-enabled'
                    type="radio"
                    label={translator('none')}
                    checked={!params.aqua_enabled && !params.wes_enabled}
                    onChange={() => updateParams('testing_framework', 'none')}
                    className="mb-0"
                  />
                </div>
                <div>
                  <Form.Check 
                    id='aqua-enabled'
                    type="radio"
                    label={translator('aqua enabled')}
                    checked={params.aqua_enabled}
                    onChange={() => updateParams('aqua_enabled', true)}
                    className="mb-0"
                  />
                </div>
                <div>
                  <Form.Check 
                    id='wes-enabled'
                    type="radio"
                    label={translator('wes enabled')}
                    checked={params.wes_enabled} 
                    onChange={() => updateParams('wes_enabled', true)}
                    className="mb-0"
                  />
                </div>
              </div>
            </div>
            
            <div className='mb-4'>
              <Form.Label>{translator('internal order for pack')}</Form.Label>
              <Form.Check 
                id='internal-order-for-pack' 
                checked={params.internal_order_for_pack} 
                onChange={e => updateParams('internal_order_for_pack', e.target.checked)} 
                type='switch'
              />
            </div>
            
            <div className='mb-4'>
              <Form.Label>{translator('note')}</Form.Label>
              <Form.Control 
                id='note' 
                value={params.note || ''} 
                onChange={e => updateParams('note', e.target.value)} 
                as='textarea'
              />
            </div>
            
            <div className='mb-4'>
              <Form.Label>{translator('expected production done date')}</Form.Label>
              <Form.Control 
                id='expected-production-done-date' 
                value={params.expected_production_done_date || ''} 
                onChange={e => updateParams('expected_production_done_date', e.target.value)} 
                type='date' 
              />
            </div>
            
            <div className='mb-4'>
              <Form.Label>{translator('amount')}</Form.Label>
              <Form.Control 
                id='amount' 
                value={params.amount || 1} 
                onChange={e => updateParams('amount', e.target.value)} 
                type='number'
                min="1"
              />
            </div>
            
            <div className='mb-4'>
              <Form.Label>{translator('amount complete')}</Form.Label>
              <Form.Control 
                id='amount-completed' 
                value={params.amount_completed || 0} 
                onChange={e => updateParams('amount_completed', e.target.value)} 
                type='number'
                min="0"
              />
            </div>
            
            <div className='mb-4'>
              <Form.Label>{translator('serial number begins')}</Form.Label>
              <Form.Control 
                id='serial-number-begins' 
                value={params.serialNumberBegin || ''} 
                onChange={e => updateParams('serialNumberBegin', e.target.value)}
                type='number'
                min="0"
              />
            </div>
            
            <div className='mb-4'>
              <Form.Label>{translator('serial number end')}</Form.Label>
              <Form.Control 
                id='serial-number-end' 
                value={params.serialNumberEnd || ''} 
                readOnly
                className="bg-light"
              />
              <small className="text-muted">Automatically calculated as: begin + amount - 1</small>
            </div>
          </div>
          
          <div style={{minWidth: '450px'}}>
            <div className='mb-5'>
              <Form.Label>{translator('cell quality report')}</Form.Label>
              <Form.Control 
                id='cell-quality-report' 
                onChange={e => updateParams('cell_quality_report', e.target.files[0])} 
                type='file'
              />
            </div>
            
            <div className='mb-5'>
              <Form.Label>{translator('technical documents')}</Form.Label>
              <Form.Control 
                id='technical-documents' 
                onChange={e => updateParams('technical_documents', e.target.files[0])} 
                type='file'
              />
            </div>
          
            {params.technical_documents.length !== 0 && params.technical_documents.map((document, index) => (
              <div className='w-100 d-flex justify-content-between' key={document.name + index}>
                <p>{document.name}</p>
                <span 
                  onClick={e => deleteDocument(document)} 
                  className="material-symbols-outlined pointer red-color"
                >
                  delete
                </span>
              </div>
            ))}

            {!!params.cell_quality_report && <p>{'Cell Quality report (uploaded) '}</p>}

            {(currentUser.role === 'admin' || currentUser.role === 'supervisor') && (
              <>
                <div>
                  <div className='mb-1'>
                    <Form.Label>{translator('show old qspec')}</Form.Label>
                    <Form.Check 
                      id='show-old' 
                      checked={showQspecs} 
                      onChange={e => setShowQspecs(e.target.checked)} 
                      type='switch'
                    />
                  </div>
                  
                  <div 
                    className='mb-1 w-100' 
                    id='old-qspecs' 
                    style={{maxHeight: '100px', overflowX: 'hidden', overflowY: 'scroll'}}
                  >
                    {(showQspecs === true && !!currentPol.qspecs) && currentPol.qspecs.map((spec, index) => (
                      <p key={index}>
                        <a 
                          id='old-spec' 
                          className='pointer' 
                          onClick={e => updateParams('qspec', stringifyQspec(currentPol.qspecs[index].data))}
                        >
                          {spec.created_at.split('T')[0]}
                        </a>
                      </p>
                    ))}
                  </div>
                  
                  <br />
                  
                  <div>
                    <Form.Label>{translator('qspec')}</Form.Label>
                    <Form.Control 
                      id='qspec' 
                      style={{height: '250px'}} 
                      value={params.qspec || ''} 
                      onChange={e => updateParams('qspec', e.target.value)} 
                      as='textarea'
                    />
                    <br />
                    {(!!params['qspec'] && isValidJson(params['qspec'])) && (
                      <Button onClick={e => openModal()}>Show Current qspec</Button>
                    )}
                  </div>
                </div>
                
                <br />
                
                <div>
                  <div>
                    <Form.Label>{translator('show old wes spec')}</Form.Label>
                    <Form.Check 
                      id='show-old-wes' 
                      checked={showWes} 
                      onChange={e => setShowWes(e.target.checked)} 
                      type='switch'
                    />
                  </div>
                  
                  <div 
                    className='w-100' 
                    id='old-wes-specs' 
                    style={{maxHeight: '100px', overflowX: 'hidden', overflowY: 'scroll'}}
                  >
                    {(showWes === true && !!currentPol.wes_specs) && currentPol.wes_specs.map((spec, index) => (
                      <p key={index}>
                        <a 
                          id='old-wes-spec' 
                          className='pointer' 
                          onClick={e => updateParams('wes_spec', stringifyQspec(currentPol.wes_specs[index].data))}
                        >
                          {spec.created_at.split('T')[0]}
                        </a>
                      </p>
                    ))}
                  </div>
                  
                  <br />
                  
                  <div>
                    <Form.Label>{translator('wes spec')}</Form.Label>
                    <Form.Control 
                      id='wes-spec' 
                      style={{height: '250px'}} 
                      value={params.wes_spec || ''} 
                      onChange={e => updateParams('wes_spec', e.target.value)} 
                      as='textarea'
                    />
                  </div>
                </div>
              </>
            )} 
          </div>
        </Container>
        
        {warnings.length !== 0 && warnings.map((warning, index) => (
          <Alert key={"warning" + index} variant='warning'>
            {warning}
          </Alert>
        ))}
        
        {errorMessages.length !== 0 && errorMessages.map((message, index) => (
          <Alert key={index} variant='danger'>
            {message}
          </Alert>
        ))}
      </Modal.Body>
      
      <Modal.Footer>
        <Button variant="default" onClick={hideModal}>{translator('cancel')}</Button>
        <Button onClick={e => handlePol(params)} disabled={errorMessages.length !== 0}>
          {translator('save')}
        </Button>
        {(showModal === true && isValidJson(params['qspec'])) && (
          <QspecModal 
            showModal={showQspec} 
            hideModal={closeModal} 
            report={JSON.parse(params['qspec'])['params']}
          />
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default PurchaseOrderLinesModal