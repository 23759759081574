import React, { useEffect, useState } from 'react';
import { Container, Table, Row, Col, Button, Form, Spinner, OverlayTrigger, Tooltip, Toast, ToastContainer } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { getOverview } from '../../state/OverviewSlice';
import { resetShipment } from '../../state/ShipmentSlice';
import { newPol, resetPol } from '../../state/PurchaseOrderLineSlice';
import { createPo, resetPurchaseorder, updatePo, deletePo } from '../../state/PurchaseOrderSlice';

import { setOrder } from '../../scripts/helperMethods';
import { translator } from '../../scripts/translator';

import PurchaseOrderLineWidget from './PurchaseOrderLineWidget';
import PurchaseOrderModal from './PurchaseOrderModal';
import PurchaseOrderLinesModal from './PurchaseOrderLinesModal';
import DeleteModal from '../widgets/modals/DeleteModal';
import Paginator from '../widgets/Paginator';
import SearchWidget from '../widgets/SearchWidget';

function OverviewIndexComponent() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true)
  const [displayPoModal, setDisplayPoModal] = useState(false);
  const [displayPolModal, setDisplayPolModal] = useState(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [currentPo, setCurrentPo] = useState({});
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100)
  const [searchParams, setSearchParams] = useState({wst_order_number_i_cont: '', haidi_order_number_i_cont: '', purchase_order_lines_product_number_i_cont: '', notes_cont: '', purchase_order_lines_shipments_tracking_i_cont: '',  purchase_order_lines_company_id_eq: '',  s: 'created_at desc', complete_order_received_date_null: true})
  const [searchHeaders] = useState([
                                    {label: translator('wst number'), subLables: null, params: ['wst_order_number_i_cont'], options: null, type: 'text'},
                                    {label: translator('haidi number'), subLables: null, params: ['haidi_order_number_i_cont'], options: null, type: 'text'},
                                    {label: translator('item number'), subLables: null, params: ['purchase_order_lines_product_number_i_cont'], options: null, type: 'text'},
                                    {label: translator('note'), subLables: null, params: ['notes_cont'], options: null, type: 'text'},
                                    {label: translator('tracking'), subLables: null, params: ['purchase_order_lines_shipments_tracking_i_cont'], options: null, type: 'text'},
                                    {label: translator('show only open orders'), subLables: null, params: ['complete_order_received_date_null'], options: null, type: 'check'}
                                  ])
  const [activeAccordions, setActiveAcordions] = useState([]);
  const [errors, setErrors] = useState({})
  const currentUser = useSelector(state => state.session.data);
  const overviews = useSelector(state => state.overview.data);
  const poMessage = useSelector(state => state.purchaseOrder.data);
  const polMessage = useSelector(state => state.purchaseOrderLine.data);
  const shipmentsMessage = useSelector(state => state.shipment.data);

  useEffect(() => {
    if(!!searchParams){ getData({}, page) }
  }, [page, pageSize]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(overviews.length !== 0){
      setLoading(false)
    }
  }, [overviews])
  useEffect(() => {
    getData()
  },[])
  
  useEffect(() => {
    if((poMessage.message) || (polMessage.message) || (shipmentsMessage.message)){ 
      getData()
    }else{
      setErrors({...poMessage, ...polMessage, ...shipmentsMessage})
      setTimeout(() => {
        setErrors({});
      }, 8000)
    }
  }, [poMessage, polMessage, shipmentsMessage]) // eslint-disable-line react-hooks/exhaustive-deps

  const getData = (page) => {
    const params = {...searchParams}
    if(currentUser.role === 'customer'){
      params['purchase_order_lines_company_id_eq'] = currentUser.company_id
      params['complete_order_received_date_null'] = ''
    }
    if(params['complete_order_received_date_null'] === false){params['complete_order_received_date_null'] = ''}
    if(!page){setPage(0)}
    setLoading(true);
    dispatch(resetShipment());
    dispatch(resetPol());
    dispatch(resetPurchaseorder());
    dispatch(getOverview({page, pageSize, searchParams: params, token: currentUser.token}))
  }
  
  const clearSearch = () => {
    const params = {wst_order_number_i_cont: '', haidi_order_number_i_cont: '', purchase_order_lines_product_number_i_cont: '', note_cont: '', purchase_order_lines_shipments_tracking_i_cont: '', s: '', complete_order_received_date_null: true}
    setSearchParams(params)
    getData(params)
  }

  const handlePageSize = (value) => setPageSize(value);

  const hideModals = () =>{
    setDisplayPolModal(false);
    setDisplayPoModal(false);
    setDisplayDeleteModal(false)
    setCurrentPo({});
  }

  const openEdit = (po) =>{
    setCurrentPo(po)
    setDisplayPoModal(true)
  } 

  const savePo = (params) => {
    Object.keys(currentPo).length !== 0 ? dispatch(updatePo({id: currentPo.id, params, token: currentUser.token})) : dispatch(createPo({params, token: currentUser.token}));
    hideModals();
    //getData();
  }

  const openPolModal = (po) => {
    setCurrentPo(po);
    setDisplayPolModal(true);
  }

  const savePol = (params) => {
    if(!params.cell_quality_report){delete params['cell_quality_report']}
    if(!params.company_id){delete params['company_id']}
    if(params.qspec.length === 0){ delete params['qspec']}
    if(params.wes_spec.length === 0){delete params['wes_spec']}
    params.purchase_order_id = currentPo.id;
    const formData = new FormData();
    for(let key of Object.keys(params)){
      if(key === 'technical_documents'){
        for(let i = 0; i < params[key].length; i++){
          formData.append(`purchase_order_line[${key}][]`, params[key][i])
        }
      }else{
        formData.append(`purchase_order_line[${key}]`, params[key]);
      }
    }
    dispatch(newPol({params: formData, token: currentUser.token}))
    setCurrentPo(null);
  } 

  const openDeleteModal = (po) =>{
    setCurrentPo(po);
    setDisplayDeleteModal(true);
  }

  const deleteConfirmedPo = (po) =>{
    dispatch(deletePo({id: po.id, token: currentUser.token}))
    hideModals();
  } 

  const handleAccordions = (checked) => {
    checked === true ? setActiveAcordions(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']) : setActiveAcordions([]);
    getData();
  }

  const pointer = (element) => searchParams['s'] === `${element} asc` ? <span className="material-symbols-outlined"> arrow_drop_down </span> : <span className="material-symbols-outlined"> arrow_drop_up </span>

  return (
    <Container fluid>
      {Object.keys(errors).length !== 0  && 
        <ToastContainer position="middle-end" className="p-3">
          {Object.keys(errors).map((error, index) => {
            return(
              <Toast key={error}>
                <Toast.Body>
                  {errors[error].map(message => <p><strong>{error}:</strong> {message}</p>)}
                </Toast.Body>
              </Toast>
            )
          })}
        </ToastContainer>
      }
      <Container className='my-5 w-50 mx-auto'>
        {(!!currentUser && currentUser.role !== 'customer') &&<h1><span className="material-symbols-outlined">search</span></h1>}
        {(!!currentUser && currentUser.role !== 'customer') && <SearchWidget searchParams={searchParams} searchHeaders={searchHeaders} setSearchParams={setSearchParams} search={getData} resetSearch={clearSearch}/>}
        
        <div className='d-flex' style={{position: 'relative', right: '-80%'}}>
          <Form.Label>{translator('open all fields')}</Form.Label>
          <div className='m-1'/>
          <Form.Check checked={activeAccordions.length !== 0 } onChange={e => handleAccordions(e.target.checked)} type='switch'/>
        </div>
      </Container>
      
      <br />
      <br />
      <Row>
        <Col>
          {(!!currentUser && currentUser.role !== 'customer') && <Button onClick={e => setDisplayPoModal(true)}>{translator('new purchase order')}</Button>}
          <br />
          <br />
          <Row>
            <Col className='d-flex align-items-center'>
              <Paginator count={overviews.purchase_orders_total} pageSize={pageSize} page={page} setPage={setPage}/>
            </Col>
            <Col className='d-flex justify-content-end'>
              <div className='w-25'>
                <Form.Label>{translator('page size')}</Form.Label>
                <Form.Select value={searchParams.pagesize} onChange={e => handlePageSize(e.target.value)} as='number'>
                  <option value={100}>100</option>
                  <option value={500}>500</option>
                  <option value={1000}>1000</option>
                </Form.Select>
              </div>
              
            </Col>
          </Row>
            {loading === true &&
              <Col className='d-flex justify-content-center'>
                <Spinner size='lg' animation="grow" />
              </Col>
            }
            {loading === false &&
              <Table striped hover size="sm" className="table-font">
                <thead>
                  <tr>
                    <th></th>
                    <th className='pointer' onClick={e => setOrder(searchParams, setSearchParams, 'wst_order_number', getData)}>{translator('wst number')} {pointer('wst_order_number')}</th>
                    <th>{translator('technical document delivered')}</th>
                    <th  className='pointer' onClick={e => setOrder(searchParams, setSearchParams, 'haidi_order_number', getData)}>{translator('haidi number')} {pointer('haidi_order_number')}</th>
                    <th className='pointer' onClick={e => setOrder(searchParams, setSearchParams, 'created_at', getData)}>{translator('placed')} {pointer('created_at')}</th>
                    {searchParams['purchase_order_lines_all_received_date_null'] === false && <th className='pointer' onClick={e => setOrder(searchParams, setSearchParams, 'complete_order_received_date', getData)}>{translator('all done')} {pointer('complete_order_received_date')}</th>}
                    <th>{translator('notes')}</th>
                    <th>{translator('purchase order lines')}</th>
                  </tr>
                </thead>
                <tbody>
                  {overviews.purchaseorders && overviews.purchaseorders.map((overview, index) => {return(
                    <tr key={index}>
                      <td id='actions'>
                      {(!!currentUser && currentUser.role !== 'customer') &&
                        <div className='d-flex'>
                          <Button id='new-pol' className='d-flex action-button' onClick={e => openPolModal(overview)}><span className="material-symbols-outlined">add</span></Button>
                          <Button id='update-po' variant='warning' className='d-flex action-button' onClick={e => openEdit(overview)}><span className="material-symbols-outlined">edit</span></Button>
                          {currentUser.role === 'admin' && <Button id='delete-po' variant='danger' className='d-flex action-button' onClick={e => openDeleteModal(overview)}><span className="material-symbols-outlined">delete</span></Button>}
                        </div>
                      }
                      </td>
                      <td id='wst-number'><p>{overview.wst_order_number}</p></td>
                      <td><div className="tdd-marker" style={{backgroundColor: overview.technical_data_delivered}}/></td>
                      <td id='haidi-number'>{overview.haidi_order_number}</td>
                      <td >{overview.created_at.split('T')[0]}</td>
                      { searchParams['purchase_order_lines_all_received_date_null'] === false && <td >{overview.complete_order_received_date}</td>}
                      <td >
                        {overview.note && 
                          <OverlayTrigger  overlay={<Tooltip id="tooltip-disabled">{overview.note}</Tooltip>}>
                            <span className="material-symbols-outlined pointer">info</span>
                          </OverlayTrigger>
                        }
                      </td>
                      
                      <td id='purchase-order-lines' style={{width: '75%'}}>
                        <PurchaseOrderLineWidget purchaseOrderLines={overview.purchase_order_lines} purchaseOrderId={overview.id} activeAccordions={activeAccordions}/>
                      </td>
                    </tr>
                  )})}
                </tbody>
              </Table>
            }
            <Col className='d-flex align-items-center'>
              <Paginator count={overviews.purchase_orders_total} pageSize={pageSize} page={page} setPage={setPage}/>
            </Col>
            <Col className='d-flex justify-content-end'>
            </Col>
        </Col>
      </Row>
      {displayPoModal === true && <PurchaseOrderModal showModal={displayPoModal} hideModal={hideModals} savePo={savePo} purchaseOrder={currentPo} currentUser={currentUser}/>}
      {displayPolModal === true && <PurchaseOrderLinesModal showModal={displayPolModal} hideModal={hideModals} savePol={savePol} currentPol={{}} currentUser={currentUser}/>}
      {displayDeleteModal === true && <DeleteModal showModal={displayDeleteModal} confirmModal={deleteConfirmedPo} hideModal={hideModals} element={currentPo} message={translator('are you sure you want to delete purchase order') + ` ${currentPo.wst_order_number} ?`}/>}
      <br />
      <br/>
    </Container>
  )
}

export default OverviewIndexComponent