import React, {useState, useEffect} from 'react'
import { Container, Modal, Button, Form, Alert, DropdownButton, InputGroup} from 'react-bootstrap'

import { updateParams } from '../../scripts/helperMethods'
import { translator } from '../../scripts/translator'

import BatteryForm from './BatteryForm'
import ChargerForm from './ChargerForm'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'

function RmaModal({showModal, hideModal, suppliers, customers, batteryModels, currentUser, currentRma, save, faults, rmaNumbers}) {
  const [formFields, setFormFields] = useState({type: 'BatteryRma', status: 'created', received_date: '', completed_date: '', customer_rma_number: '', wst_rma_number: '', 
                                                supplier_rma_number: '', customer_issue_description: '', warranty_wst_status: 'no_claim', root_cause_analysis: '', notes: '', 
                                                customer_id: 3, checked_by_id: '', replacement_percentage: 0, fault_description: '', supplier_id: 2, 
                                                warranty_supplier_status: 'no_claim', replacement_spent: 0, delivery_date: '', warranty_length: 24,  specific_rma_data: {}, files: []}) 
  const [batteryFormFields, setBatteryFormFields] = useState({
                                                              serial_number: '',  product_model: "",  dsg: '', chg: '', comunication: '', casing_voltage: false, cycle_number: '', 
                                                              uv_number: '', ov_number: '', sc_number: '', uv_cycle_ratio: '', ov_cycle_ratio: '', sc_scycle_ratio: '', 
                                                              cycle_number: '',
                                                            })

  const [chargerFormFields, setChargerFormFields] = useState({product_model: '', aerial_number: '',  charging: false})

  const [errorMessages, setErrorMessage] = useState([]) 

  useEffect(() => {
    if(currentRma !== null){
      setRmaData()
    }else{
      resetFields()
      currentRmaNumber()
    }
  },[currentRma, showModal])

  useEffect(() => {
    checkErrors()
  },[formFields, batteryFormFields])
  
  const updateForm = (key, value) =>{
    const params = {...formFields}
    params[key] = value;
    setFormFields(params);
  }

  const setRmaData = () => {
    const formFields = {...currentRma}
    formFields['customer_id'] = findId(customers, currentRma.customer)
    formFields['supplier_id'] = findId(suppliers, currentRma.supplier)
    delete formFields.supplier
    delete formFields.customer
    delete formFields.checked_by
    delete formFields.id
    setFormFields(formFields)
    if(currentRma.type === 'BatteryRma' && currentRma.specific_rma_data !== null){setBatteryFormFields(currentRma.specific_rma_data)}
    if(currentRma.type === 'ChargerRma' && currentRma.specific_rma_data !== null){setChargerFormFields(currentRma.specific_rma_data)}
  }

  // finds id from 
  const findId = (array, string) => {
    let id = 0;
    for(let element of array){
      if(element.name === string){ id = element.id }
    }
    return id
  }

  const updateBatteryForm = (formFields) => setBatteryFormFields(formFields)

  const updateChargerForm = (params) => setChargerFormFields(params) 
  
  const resetFields = () => {
    setFormFields({type: 'BatteryRma', status: 'created', received_date: '', completed_date: '', customer_rma_number: '', wst_rma_number: '', supplier_rma_number: '', 
    customer_issue_description: '', warranty_wst_status: 'unknown', root_cause_analysis: '', notes: '', customer_id: 3, checked_by_id: '', replacement_percentage: 0, 
    fault_description: '', supplier_id: 2, warranty_supplier_status: 'unknown', replacement_spent: 0, delivery_date: '', warranty_length: 24,  specific_rma_data: {}, files: []})
    setBatteryFormFields({serial_number: '',  product_model: "",  dsg: 'N/A', chg: 'N/A', comunication: 'N/A', casing_voltage: false, cycle_number: '',   uv_number: '', 
                          ov_number: '', sc_number: '', uv_cycle_ratio: '', ov_cycle_ratio: '', sc_cycle_ratio: ''})
    setChargerFormFields({product_model: '', charging: false})
  }

  const addFiles = (files, index) =>{
    const formParams = {...formFields};
    for(let file of files){
      if(typeof file != 'undefined'){
        formParams['files'] = [...formParams['files'], file]
      }
    }
    setFormFields(formParams);
  }

  const deleteFile = async (id, index) => {
    const newParams = {...formFields};
    if(!!id){
      const data  = await fetch( `${process.env.REACT_APP_API_URL}/rmas/delete_file?id=${id}`, {
        method: 'Get',
        headers: { token: currentUser.token }
      })
      const response = await data.json()
    }
    newParams['files'].splice(index, 1)
    setFormFields(newParams)
    
  }

  const checkErrors = () => {
    let errors = []

   if(currentRma === null){ if(!formFields['type'] || formFields['type'].length === 0 ){errors.push(translator('type cannot be blank'))}
    if(!formFields['status'] || formFields['status'].length === 0 ){errors.push(translator('status cannot be blank'))}
    if(!formFields['received_date'] || formFields['received_date'].length === 0 ){errors.push(translator('recieved date cannot be blank'))}
    if(!formFields['wst_rma_number'] || formFields['wst_rma_number'].length === 0 ){errors.push(translator('wst rma number cannot be blank'))}
    if(rmaNumbers.includes(parseInt(formFields['wst_rma_number']))){errors.push(translator('wst rma numer allready exists'))}
   }

    if(formFields['type'] === 'BatteryRma' && (!!currentRma && currentRma.length !== 0) ){
      if(!batteryFormFields['serial_number'] || batteryFormFields['serial_number'].length === 0 ){errors.push(translator('serial number cannot be blank'))}
      if(!batteryFormFields['product_model'] || batteryFormFields['product_model'].length === 0 ){errors.push(translator('model cannot be blank'))}
     }
    setErrorMessage(errors)
  }

  const saveRma = () => {
    if(errorMessages.length === 0){
      const params = {...formFields}
      if(params['type'] === 'ChargerRma'){
        params['specific_rma_data'] = {...chargerFormFields}
      }else{
        params['specific_rma_data'] = {...batteryFormFields}
      }
      
      save(params)
    }
  }

  const calculatePercentage = () => {
    const params = {...formFields}
    const warrantyInDays = (30.41 * params['warranty_length']).toFixed(0)
    if(params.replacement_percentage == 0 ){
      if(params.delivery_date.length !== 0 && params.received_date !== 0 ){
        const recievedDate = new Date(params.received_date)
        const deliveryDate = new Date(params.delivery_date)
        const totalAge = (recievedDate.getTime() - deliveryDate.getTime()) / (1000 * 3600 *24)
        let calc = 100 - ((totalAge / warrantyInDays) * 100).toFixed(0)
        if(calc < 0){calc = 0}
        if(calc > 100){calc = 100}
        params['replacement_percentage'] = calc;
      }
    }
    setFormFields(params);
  }

  const showCalcButton = () => !!formFields.delivery_date && formFields.delivery_date.length !== 0 && formFields.received_date.length !== 0 && formFields.replacement_percentage == 0;

  const calculateWarranty = () => {
    const params = {...formFields}
    let warrantyBad = false
    const deliveryDate = new Date(params.delivery_date)
    const recievedDate = new Date(params.received_date)
    if(batteryFormFields['ov_cycle_ratio'].length !== 0 && parseInt(batteryFormFields['ov_cycle_ratio']) >= 0.05){warrantyBad = true}
    if(batteryFormFields['uv_cycle_ratio'].length !== 0 && parseInt(batteryFormFields['uv_cycle_ratio']) >= 0.05){warrantyBad = true}
    if(batteryFormFields['sc_cycle_ratio'].length !== 0 && parseInt(batteryFormFields['sc_cycle_ratio']) > 0.000){warrantyBad = true}
    if(((recievedDate.getTime() - deliveryDate.getTime() ) / (1000 * 3600 *24)) > ((30.41 * parseInt(params['warranty_length'])).toFixed(0))){warrantyBad = true}

    if(warrantyBad){
      params['warranty_wst_status'] = 'rejected'
    }else{
      params['warranty_wst_status'] = 'accepted'

    }
    setFormFields(params)
  }

  const currentRmaNumber = () => {
    const params = formFields
    let largestNum = rmaNumbers[0]
    for(let number of rmaNumbers){
      if(number > largestNum){largestNum = number}
    }
    
    params.wst_rma_number = largestNum >= 10001 ? largestNum +1 : 10001;
    setFormFields(params)
  }
 
  return (
    <Modal size='xl' show={showModal} onHide={hideModal} backdrop='static' >
      <Modal.Header closeButton >
        <Modal.Title>{currentRma !== null ? translator('update rma') : translator('new rma')}</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <Container className='d-flex flex-wrap justify-content-between'>
          
          {!(!!currentRma) && <div className='modal-field-large m-2' hidden={true}>
            <Form.Label>{translator('checked by')}</Form.Label>
            <Form.Control value={currentUser.id}  disabled={true}/> 
          </div>}

          <div className='modal-field-large m-2'>
            <Form.Label>{translator('rma type')}</Form.Label>
            <Form.Select id='rma-type' value={formFields['type']} onChange={e => updateParams(formFields, setFormFields, 'type', e.target.value)}>
              <option value="BatteryRma">{translator('battery')}</option>
              <option value="ChargerRma">{translator('charger')}</option>
              <option value="OtherRma">{translator('other')}</option>
            </Form.Select>
          </div>

          <div className='modal-field-large m-2'>
            <Form.Label>{translator('status')}</Form.Label>
            <Form.Select id='status' value={formFields['status']} onChange={e => updateParams(formFields, setFormFields, 'status', e.target.value)} >
              <option value="created">{translator('created')}</option>
              <option value="received">{translator('received')}</option>
              <option value="working">{translator('working')}</option>
              <option value="supervisor_help">{translator('supervisor help')}</option>
              <option value="waiting_on_supplier">{translator('waiting on supplier')}</option>
              <option value="processed">{translator('processed')}</option>
              <option value="discarded">{translator('discarded')}</option>
              <option value="returned">{translator('returned')}</option>
            </Form.Select>
          </div>

          <div className='modal-field-large m-2'>
            <Form.Label>{translator('customer Rma')}</Form.Label>
            <Form.Control id='customer-rma' value={formFields['customer_rma_number']} onChange={e => updateParams(formFields, setFormFields, 'customer_rma_number', e.target.value)}/>
          </div>

          <div className='modal-field-large m-2'>
            <Form.Label>{translator('wst rma')}</Form.Label>
            <Form.Control id='wst-rma' value={formFields['wst_rma_number'] || ''} onChange={e => updateParams(formFields, setFormFields, 'wst_rma_number', e.target.value)} type='number'/>
          </div>

          <div className='modal-field-large m-2'>
            <Form.Label>{translator('supplier rma')}</Form.Label>
            <Form.Control id='supplier-rma' value={formFields['supplier_rma_number']} onChange={e => updateParams(formFields, setFormFields, 'supplier_rma_number', e.target.value)}/>
          </div>

          <div className='modal-field-large m-2'>
            <Form.Label>{translator('delivery date')}</Form.Label>
            <Form.Control id='delivery-date' value={formFields['delivery_date']} onChange={e => updateParams(formFields, setFormFields, 'delivery_date', e.target.value)} type='date'/>
          </div>

          <div className='modal-field-large m-2'>
            <Form.Label>{translator('received date')}</Form.Label>
            <Form.Control id='recieved-date' value={formFields['received_date']} onChange={e => updateParams(formFields, setFormFields, 'received_date', e.target.value)} type='date'/>
          </div>

          <div className='modal-field-large m-2'>
            <Form.Label>{translator('completed date')}</Form.Label>
            <Form.Control id='completed-date' value={formFields['completed_date']} onChange={e => updateParams(formFields, setFormFields, 'completed_date', e.target.value)} type='date'/>
          </div>

          <div className='modal-field-large m-2'>
            <Form.Label>{translator('warranty length')}</Form.Label>
            <Form.Control id='warranty-length' value={formFields['warranty_length']} onChange={e => updateParams(formFields, setFormFields, 'warranty_length', e.target.value)} type='number'/>
          </div>

          <div className='modal-field-large m-2'>
            <Form.Label>{translator('cusotmer')}</Form.Label>
            <Form.Select id='customer' value={formFields['customer_id']} onChange={e => updateParams(formFields, setFormFields, 'customer_id', e.target.value)}>
              {Array.isArray(customers) && customers.map((customer) => <option key={customer.name} value={customer.id}>{customer.name}</option>)}
            </Form.Select>
          </div>

          <div className='modal-field-large m-2'>
            <Form.Label>{translator('supplier')}</Form.Label>
            <Form.Select id='supplier' value={formFields['supplier_id']} onChange={e => updateParams(formFields, setFormFields, 'supplier_id', e.target.value)}>
              {Array.isArray(suppliers) && suppliers.map((supplier) => <option key={supplier.name} value={supplier.id}>{supplier.name}</option>)}
            </Form.Select>
          </div>

          <div className='modal-field-large m-2'>
            <Form.Label>{translator('wst warranty')}</Form.Label>
            <Form.Select id='wst-warranty' value={formFields['warranty_wst_status']} onChange={e => updateParams(formFields, setFormFields, 'warranty_wst_status', e.target.value)}>
              <option value="no_claim">{translator('no claim')}</option>
              <option value="accepted">{translator('accepted')}</option>
              <option value="rejected">{translator('rejected')}</option>
              <option value="pending">{translator('pending')}</option>
            </Form.Select>
          </div>

          <div className='modal-field-large m-2'>
            <Form.Label>{translator('supplier warranty')}</Form.Label>
            <Form.Select id='supplier-warranty' value={formFields['warranty_supplier_status']} onChange={e => updateParams(formFields, setFormFields, 'warranty_supplier_status', e.target.value)}>
            <option value="no_claim">{translator('no claim')}</option>
              <option value="accepted">{translator('accepted')}</option>
              <option value="rejected">{translator('rejected')}</option>
              <option value="pending">{translator('pending')}</option>
            </Form.Select>
          </div>

          <div className='modal-field-large m-2'>
            <Form.Label>{translator('replacement percentage')}</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control id='replacement-percentage' value={formFields['replacement_percentage']} onChange={e => updateParams(formFields, setFormFields, 'replacement_percentage', e.target.value)} type='number'/>
              <Button id='calculate-percentage' onClick={e => calculatePercentage()} disabled={!showCalcButton()} variant={showCalcButton() ? 'primary': 'secondary'} > Calc</Button>
            </InputGroup>
          </div>

          <div className='modal-field-large m-2'>
            <Form.Label>{translator('replacement spent')}</Form.Label>
            <Form.Control id='replacement-spent' value={formFields['replacement_spent']} onChange={e => updateParams(formFields, setFormFields, 'replacement_spent', e.target.value)}  type='number'/> 
          </div>
          
          <div className='modal-field-large m-2'>
            <Form.Label>{translator('fault description')}</Form.Label>

            <Form.Select id='fault-description' onChange={e => updateForm('fault_description', e.target.value)}>
              <option value="">{translator('select fault description')}</option>
              <option value="CP8-6: Vc.diff> 300mV">{'CP8-6: Vc.diff> 300mV'}</option>
              <option value="Assembly error">{translator('assembly error')}</option>
              <option value="Deep discharged">{translator('deep discharged')}</option>
              <option value="BMS Issue">{translator('bms issue')}</option>
              <option value="No Issue">{translator('no issue')}</option>
              <option value="Bricked">{translator('bricked')}</option>
              <option value="Vc.diff> 300mV">{'Vc.diff> 300mV'}</option>
              <option value="Parameter lssue">{translator('parameter issue')}</option>
              <option value="Charger Issue">{translator('charger issue')}</option>
              <option value="Cell Issue">{translator('cell issue')}</option>
              <option value="Handling error">{translator('handling error')}</option>
              <option value="N/A">{translator('na')}</option>
            </Form.Select>
          </div>

          <div className='modal-text-field-large w-100 m-2'>
            <Form.Label>{translator('customer issue')}</Form.Label>
            <Form.Control id='customer-issue' value={formFields['customer_issue_description']} onChange={e => updateParams(formFields, setFormFields, 'customer_issue_description', e.target.value)} as='textarea'/>
          </div>

          <div className='modal-text-field-large w-100 m-2'>
            <Form.Label>{translator('root cause analysis')}</Form.Label>
            <Form.Control id='rca' value={formFields['root_cause_analysis']} onChange={e => updateParams(formFields, setFormFields, 'root_cause_analysis', e.target.value)} as='textarea'/>
          </div>

          <div className='modal-text-field-large w-100 m-2'>
            <Form.Label>{translator('notes')}</Form.Label>
            <Form.Control id='notes' value={formFields['notes']} onChange={e => updateParams(formFields, setFormFields, 'notes', e.target.value)} as='textarea'/>
          </div>

          {formFields['type'] === 'BatteryRma' && 
            <div className='w -100'>
              <BatteryForm batteryModels={batteryModels} batteryFormFields={batteryFormFields} updateBatteryForm={updateBatteryForm}/>
              <br />
              <Button onClick={e => calculateWarranty()}>{translator('calculate warranty')}</Button>
            </div>
          }
          {formFields['type'] === 'ChargerRma' && 
            <div className='w-100'>
              <ChargerForm chargerFormFields={chargerFormFields} updateChargerForm={updateChargerForm} />
            </div>
          }
          
          <div>
            <br />
            <h5>{translator('files')}</h5>
            {formFields['files'].map((file, index) => {return(
              <div key={file.name  + index} className='w-100 d-flex justify-content-between'>
                <p>{file.name}</p>
                <span onClick={e => deleteFile(file.id, index)} className="material-symbols-outlined pointer red-color">delete</span>
              </div>
              
            ) })}
            <br/>
            <Form.Label>{('add file')}</Form.Label>
            <Form.Control id='files' onChange={e => addFiles(e.target.files)} type='file' multiple/>
            <br />
          </div>
                
          {errorMessages.length !== 0 && errorMessages.map((error) => <Alert key={error} variant='danger' className='w-100'>{error}</Alert>)}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideModal}>{translator('cancel')}</Button>
        <Button onClick={e => saveRma()} disabled={errorMessages.length !== 0 }>{translator('save')}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RmaModal